import React, { useEffect, useState } from 'react';
import HeroSVG from '../components/HeroSVG';
import GrowthSVG from '../components/GrowthSVG';
import NetworkSVG from '../components/NetworkSVG';
import LeavesSVG from '../components/LeavesSVG';
import Navbar from '../components/NavBar';
import BasicModal from '../components/BasicModal';
import MarketingFooter from '../components/MarketingFooter';
import { Link, navigate } from 'gatsby';
import calculate from '../images/home/calculate.png';
import track from '../images/home/track.png';
import goals from '../images/home/goals.png';
import integrate from '../images/home/integrate.png';
import sticker from '../images/Sticker_Ton_Redwoods.jpg';
import sticker2 from '../images/Sticker_Ton_Beach.jpg';
import { Button } from '@chakra-ui/react';
import MarketingHead from '../components/marketing-meta';

const IndexPage = () => {
  const [salesModal, setSalesModal] = useState(false);


  // load the HS script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {

    })

  }, []);

  // populate a lil form
  useEffect(() => {
    if(window.hbspt && salesModal) {
      window.hbspt.forms.create({
        portalId: '14557468',
        formId: '04f1e797-42b8-4238-90a6-40fd9c2e8496',
        target: '#hubspotForm'
      })
    }
  }, [salesModal])

  return (
    <>
      <BasicModal title="Let's get in touch" isOpen={salesModal} close={() => setSalesModal(false)}>
        Interested in funding your project through Kodama's open exchange? Get in touch to list your project on the only open, public carbon offset exchange.
        <div id="hubspotForm"/>
      </BasicModal>
      <title>Kodama - The Carbon Exchange API</title>
      <MarketingHead />
      <div className={'bg-hero-image bg-cover p-1 text-white'}>
        <Navbar />
        <div className="container xl:px-40 mt-10 mb-5 mx-auto">
          <div className={'h-auto min-h-96 p-2 grid grid-cols-1 lg:grid-cols-2'}>
            <div className="flex items-center lg:pr-4">
              <div>
                <h1 className="text-5xl font-bold leading-tight">Bring sustainability to any business.</h1>
                <div className="my-8 h-1 bg-white w-3/4"></div>
                <p className="mb-8">With Kodama, it's easy to set, track, and achieve sustainability goals. Get started on your path to sustianable business today.</p>
                {/* <button className="bg-gray-50 hover:bg-gray-100 h-10 px-5 py-1 mr-3 text-primary-dark rounded">Get Started</button>
                <button className="px-5 py-1 hover:bg-green-900 mr-2 border-2 h-10 border-white rounded">API Docs</button> */}
                <button onClick={() => navigate('/app/register')} className="bg-green-100 text-lg hover:bg-gray-100 h-10 px-7 py-1 mr-3 text-gray-900 rounded">Get Started</button>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div className="lg:pl-4 mt-10 lg:mt-0">
                <HeroSVG className="max-w-full xl:max-w-none xl:w-6/5 h-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="container xl:px-40 mt-36 mb-24 mx-auto px-2">
        {/* <div className="text-center my-24">
          <h2 className="text-2xl font-bold text-center text-primary-dark">Kodama's API Features</h2>
          <p>Learn what Kodama can do for you</p>
          <div className="mt-4 h-1 mx-auto bg-primary w-1/4"></div>
        </div> */}
        <div className="my-24 grid grid-cols-1 lg:grid-cols-3 items-center p-4 lg:p-0">
          <div>
            <h2 className="text-4xl text-primary font-bold mb-4">Your digital sustainability consultant.</h2>
            <div className="border inline-block border-primary text-primary px-4 py-2 rounded-md"><Link to="/exchange" >See all features</Link></div>
          </div>
          <div className="text-xl col-span-1 lg:col-span-2 text-gray-700 pl-3 ml-2 h-full border-l border-primary flex items-center mt-8 lg:mt-0">
            Kodama helps you track and offset your emissions in real-time. Kodama makes it easy to reach your sustainability goals.
            Our goal is to make it dead simple to show your customers and stakeholders the impact you have on the environment.
          </div>
        </div>
        {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mx-3 xl:mx-0">
          <div className="shadow-sm lg:shadow-xl border border-gray-200 p-4 rounded-xl transition ease-in duration-100 transform hover:scale-105">
            <div className="flex justify-center mb-5">
              <GrowthSVG className="h-16 max-w-16 fill-current text-primary" />
            </div>
            <h4 className="text-xl font-bold text-center mb-2">Buy Carbon Credits</h4>
            <p className="leading-loose">Easily purchase carbon credits and offset your footprint through Kodama's API and exchange. </p>
          </div>
          <div className="shadow-sm lg:shadow-xl  border border-gray-200 p-4 rounded-xl transition ease-in duration-100 transform hover:scale-105">
            <div className="flex justify-center mb-5">
              <NetworkSVG className="h-16 max-w-16 fill-current text-primary" />
            </div>
            <h4 className="text-xl font-bold text-center mb-2">Open Exchange</h4>
            <p className="leading-loose">Kodama allows any carbon credits registered with a verified standard to be listed on the exchange.</p>
          </div>
          <div className="shadow-sm lg:shadow-xl border border-gray-200 p-4 rounded-xl transition ease-in duration-100 transform hover:scale-105">
            <div className="flex justify-center mb-5">
              <LeavesSVG className="h-16 max-w-16 fill-current text-primary" />
            </div>
            <h4 className="text-xl font-bold text-center mb-2">Track your footprint</h4>
            <p className="leading-loose">
              Kodama can help you estimate and track your emissions and offsets in real-time. Show your customers how you're making the world a better place
            </p>
          </div>
        </div> */}
      </div>

      <div className="container xl:px-40 px-4 my-36 mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-24 items-center">
          <div className="rounded-lg order-last lg:order-first text-primary">
            <h2 className="text-4xl mb-4 font-bold">Intelligent Footprint Calculation</h2>
            <p className="leading-loose text-gray-800">
              Tools that accurately compute your business’s carbon emissions. Understand how each part of a business contributes to its environmental impact. Show how changes could reduce emissions.
            </p>
          </div>
          <div className="lg:px-24">
            <img src={calculate} />
          </div>
        </div>
      </div>
      <div className="container xl:px-40 px-4 my-36 mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-24 items-center">
          <div>
            <img src={track} />
          </div>
          <div className="rounded-lg text-primary">
            <h2 className="text-4xl mb-4 font-bold">Realtime Automated Emission Tracking</h2>
            <p className="leading-loose text-gray-800">
            Track emissions as they happen, track changes and effective reduction of emissions, and track sustainability goals. A live ledger of your company’s carbon footprint. Automatically import from your existing systems and correlate emissions to their offsets.
            </p>
          </div>
        </div>
      </div>
      <div className="container xl:px-40 px-4 my-36 mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-24 items-center">
          <div className="rounded-lg order-last lg:order-first text-primary">
            <h2 className="text-4xl mb-4 font-bold">Automatically Offset to Meet Goals</h2>
            <p className="leading-loose text-gray-800">
            Offset emissions to meet your sustainability goals. Integrate carbon neutrality into products automatically. Kodama can help automatically meet sustainability goals through offsets. Use Kodama to track KPIs and your progress towards the goals you’ve set.            </p>
          </div>
          <div className="lg:px-24">
            <img src={goals} />
          </div>
        </div>
      </div>
      <div className="container xl:px-40 px-4 my-36 mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-24 items-center">
          <div className="lg:px-24">
            <img src={integrate} />
          </div>
          <div className="rounded-lg text-primary">
            <h2 className="text-4xl mb-4 font-bold">Integrate into your Processes</h2>
            <p className="leading-loose text-gray-800">
            Kodama is built tech-first. We’re building our product and APIs with integration in mind. We want to make it easy to add Kodama into existing business processes and deeply integrate sustainability.            </p>
          </div>
        </div>
      </div>

      <div className="container xl:px-40 px-4 my-24 mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="p-24 rounded-lg text-primary text-center">
            <h2 className="text-4xl mb-4 font-bold ">About Credits</h2>
            <p className="leading-loose text-gray-800">
              Carbon offsets, or voluntary carbon credits, are one way your business can achieve your sustainability goals. These offsets help to fund projects that either actively remove carbon from the atmospher, or provide a real reduction in carbon emissions on a large scale.
              Kodama makes it easy to purchase offsets to help make your business more environmentally conscious.
            </p>
          </div>
          <div className="p-24 rounded-lg shadow-lg text-white text-left bg-cover bg-beach-image">
            <p className="text-3xl font-bold">
              Go beyond greenwashing
            </p>
            <p>
              Take a step towards accountability and transparency by using the only real-time carbon tracking and offset platform. You're not just offsetting your emissions, your doing it in a transparent accountable way.
            </p>
            <p>
              Kodama's goal is to help you show your customers and stakeholders how you're going on notional greenwashing.
            </p>
            <button onClick={() => navigate('/exchange')} className="border border-white rounded-md text-white px-4 py-2 mt-4">
                Learn More
            </button>
          </div>
        </div>
      </div>

      <div className="container xl:px-40 px-4 my-24 mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="p-8 flex space-x-4 justify-center">
            <img className="max-h-80 shadow-lg" src={sticker} />
            <img className="max-h-80 shadow-lg" src={sticker2} />
          </div>
          <div className="rounded-lg text-primary flex-col flex justify-center">
            <h2 className="text-4xl mb-4 font-bold ">Buy an offset</h2>
            <p className="leading-loose text-gray-800">
             Not ready to commit to sustainability for your business, but still want to offset? Buy a single offset with Kodama's stickers. Each Offsets 1 ton of carbon dioxide.
            </p>
            <Button colorScheme="green" justifyContent="none" size="lg" mt={2} onClick={() => {navigate("/stickers")}} variant="link">Buy now</Button>
          </div>
        </div>
      </div>

      <div className="bg-gray-100">
      <div className="container xl:px-40 py-6 mx-auto px-4 lg:px-0">
        <div className="my-24 grid grid-cols-1 lg:grid-cols-2 items-center">
          <div className="text-xl text-gray-700 pr-2 ml-2 h-full flex items-center">
            You track your accounting and expenses, why not track where your carbon impact comes from?
          </div>
          <div className="mt-4 lg:mt-0 lg:pl-4 lg:border-l lg:border-primary">
            <h2 className="text-4xl text-primary font-bold mb-4 ">Accounting for your emissions</h2>
            <div className="border inline-block border-primary text-primary px-4 py-2 rounded-md"><Link to="/tracking" >Learn about tracking</Link></div>
          </div>
        </div>
      </div>
      </div>

      <div className="container xl:px-40 mt-24 mx-auto">
        <div className="rounded-lg shadow-lg overflow-hidden border border-gray-100 flex flex-row mx-4 xl:mx-0">
          <div className="bg-primary p-10 w-full lg:w-1/2">
            <h2 className="text-4xl text-white font-bold">Sell Offsets with Kodama</h2>
            <p className="text-white leading-loose mt-4">Kodama's API gives you the infrastructure to manage your carbon offsets. Sell offsets and fund your project through the Kodama API and open exchange. We only list offsets that are verified independently by registry's such as GoldStandard and Verra.</p>
            <button onClick={() => setSalesModal(true)} className="mt-4 px-5 py-1 hover:bg-green-500 mr-2 border h-10 border-white text-white rounded">Learn about funding on Kodama</button>
          </div>
          <div className="bg-sprout-image bg-cover w-1/2 opacity-50 lg:block hidden"></div>
        </div>
      </div>

      <MarketingFooter />
    </>
  );
};

export default IndexPage
